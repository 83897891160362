export const TRANSACTION_STATUS_TYPE = Object.freeze({
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
  CANCELLED_BY_SYSTEM: 'CANCELLED_BY_SYSTEM',
  CANCELLED_BY_USER: 'CANCELLED_BY_USER',
  CANCELLED: 'CANCELLED'
});

export const CANCELLED_TYPE = Object.freeze({
  AUTOMATIC: 'AUTOMATIC',
  MANUAL: 'MANUAL'
});

export const filter = [
  {
    id: 'transaction_status',
    type: 'dropdown',
    label: 'Status',
    default: 'all',
    option: [
      { text: 'Status', value: 'all' },
      { text: 'Pending', value: TRANSACTION_STATUS_TYPE.PENDING },
      { text: 'Success', value: TRANSACTION_STATUS_TYPE.SUCCESS },
      { text: 'Failed', value: TRANSACTION_STATUS_TYPE.FAILED },
      { text: 'Cancelled By System', value: TRANSACTION_STATUS_TYPE.CANCELLED_BY_SYSTEM },
      { text: 'Cancelled By User', value: TRANSACTION_STATUS_TYPE.CANCELLED_BY_USER }
    ],
    func: (query, value, setQuery = {}) => {
      if (value === TRANSACTION_STATUS_TYPE.CANCELLED_BY_SYSTEM) {
        query['status'] = TRANSACTION_STATUS_TYPE.CANCELLED;
        query['cancelledType'] = CANCELLED_TYPE.AUTOMATIC;
        setQuery['transaction_status'] = value;
      } else if (value === TRANSACTION_STATUS_TYPE.CANCELLED_BY_USER) {
        query['status'] = TRANSACTION_STATUS_TYPE.CANCELLED;
        query['cancelledType'] = CANCELLED_TYPE.MANUAL;
        setQuery['transaction_status'] = value;
      } else {
        query['status'] = value && value.length > 0 ? value : null;
        setQuery['transaction_status'] = value && value.length > 0 ? value : '';
      }
    }
  },
  {
    id: 'transactionType',
    type: 'dropdown',
    label: 'Transaction Type',
    default: 'all',
    option: [
      { text: 'Transaction Type', value: 'all' },
      { text: 'Buy', value: 'buy' },
      { text: 'Sell', value: 'sell' }
    ]
  },
  {
    id: 'companyCode',
    type: 'dropdown',
    label: 'Company Ticker',
    default: 'all',
    option: [{ text: 'Company Ticker', value: 'all' }]
  },
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  }
];
