import React from 'react';
import PluangListPage from 'components/organisms/pluangListTable';
import { header } from './constants';

const filter = [
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  }
];
const UserVoucher = () => {
  return (
    <PluangListPage
      title="User Voucher Details"
      headers={header}
      filters={filter}
      resource="VOUCHERS"
    />
  );
};

export default UserVoucher;
