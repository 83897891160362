import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

const tableStyle = {
  [`.MuiTableCell-root`]: { border: '1px solid rgba(224, 224, 224, 1)' },
  '&:nth-of-type(odd)': { backgroundColor: 'rgba(0, 0, 0, 0.05)' }
};

const CryptoFuturesProducts = ({ userCryptoFuturesPortfolio }) => {
  const [open, setOpen] = useState(false);

  const fixedHeaders = [
    { field: 'Products' },
    { field: 'Current Balance & Unrealized Profit', col: 4 },
    { field: 'Realized Profit/Loss', col: 2 }
  ];

  const productHeaders = [
    { label: 'Futures Contract' },
    { label: 'Quantity' },
    { label: 'Avg Price' },
    { label: 'Side' },
    { label: 'Unrealized PnL' },
    { label: `Today's Realized Profit/Loss` },
    { label: 'Total Realized Profit/Loss' }
  ];

  return (
    <Accordion expanded={open} onChange={() => setOpen((prevState) => !prevState)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h5">Products</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer>
          <Table sx={{ whiteSpace: 'nowrap' }}>
            <TableBody>
              <TableRow sx={tableStyle}>
                {fixedHeaders.map((row, key) => (
                  <TableCell key={key} align="center" colSpan={row.col || 1}>
                    {row.field}
                  </TableCell>
                ))}
              </TableRow>
              <TableRow sx={tableStyle}>
                {productHeaders.length > 0 &&
                  productHeaders.map(({ label, col }) => (
                    <TableCell key={label} align="center" colSpan={col || 1}>
                      {label}
                    </TableCell>
                  ))}
              </TableRow>
              {Object.entries(userCryptoFuturesPortfolio?.cryptoFuturesPositions).map(
                ([key, position]) => (
                  <TableRow key={key} sx={tableStyle}>
                    <TableCell align="center">{position.name}</TableCell>
                    <TableCell align="center">{position.quantity}</TableCell>
                    <TableCell align="center">{position.weightedCost?.toFixed(2)}</TableCell>
                    <TableCell align="center">
                      {position.quantity?.toString().startsWith('-') ? 'Short' : 'Long'}
                    </TableCell>
                    <TableCell align="center">{position.unrealisedGain?.toFixed(2)}</TableCell>
                    <TableCell align="center">{position.todaysRealisedGain?.toFixed(2)}</TableCell>
                    <TableCell align="center">
                      {position.assetSaleRealisedGain?.toFixed(2) || 0}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

CryptoFuturesProducts.propTypes = {
  userCryptoFuturesPortfolio: PropTypes.object
};

export default CryptoFuturesProducts;
