import PluangListPage from 'components/organisms/pluangListTable';

const OptionsTransactions = () => {
  const filters = [
    {
      id: 'status',
      type: 'dropdown',
      label: 'Status',
      default: 'SUCCESS',
      options: [
        { text: 'All', value: '' },
        { text: 'Pending', value: 'PENDING' },
        { text: 'Success', value: 'SUCCESS' },
        { text: 'Cancelled', value: 'CANCELLED' },
        { text: 'Failed', value: 'FAILED' }
      ]
    },
    {
      id: 'transactionType',
      type: 'dropdown',
      label: 'Transaction Type',
      default: '',
      options: [
        { text: 'All', value: '' },
        { text: 'Long Open', value: 'long_open' },
        { text: 'Long Close', value: 'long_close' }
      ]
    },
    {
      id: 'date',
      type: 'dateRange',
      label: 'DateFilter'
    }
  ];

  const headers = [
    { header_name: 'Order Id', db_column_name: 'id' },
    { header_name: 'Order Type', db_column_name: 'orderType' },
    { header_name: 'Trans Type', db_column_name: 'transactionType' },
    { header_name: 'Quantity', db_column_name: 'quantity', roundOff: 2 },
    { header_name: 'Unit Price', db_column_name: 'unitPrice', formattedCurrency: true },
    { header_name: 'Status', db_column_name: 'displayStatus' },
    { header_name: 'Contract Symbol', db_column_name: 'contractSymbol' },
    { header_name: 'Created', db_column_name: 'created' },
    { header_name: 'Updated', db_column_name: 'updated' }
  ];

  return (
    <PluangListPage
      filters={filters}
      headers={headers}
      resource="OPTIONS_TRANSACTIONS"
      title="Options Transactions"
    />
  );
};

export default OptionsTransactions;
