import { Reply } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useStateValue } from 'utils/redux';
import Container from 'components/atoms/Container';
import Item from 'components/atoms/Item';
import BasicTable from 'components/molecules/BasicTable';
import { REMOTE } from 'constants/serverRoutes';
import { dateFormatter } from 'helpers/dateChanger';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLoader } from 'services/hooks';
import { makeRequest } from '../../../../services/APIService';

const UserInsuranceEdit = () => {
  const [appState] = useStateValue();
  const { userInsuranceId } = useParams();
  const [userInsuranceData, setUserInsuranceData] = useState({});
  const [userData, setUserData] = useState({});
  const [, setLoader] = useLoader();
  const navigate = useNavigate();

  useEffect(() => {
    userInsuranceId && getUserInsurance(userInsuranceId);
  }, [appState.sfId]);

  const getUser = (userId) => {
    if (!userId) {
      navigate(-1);
    }
    setLoader(true);
    const query = {};
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    makeRequest({
      url: `${REMOTE.USER}/${appState.sfId}`,
      params: query,
      ignoreError: true
    })
      .then((result) => {
        if (result.data?.content?.length === 1) {
          setUserData(result.data.content[0]);
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getUserInsurance = (userInsuranceId) => {
    setLoader(true);
    const query = {};
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    query.id = userInsuranceId;
    makeRequest({
      url: `${REMOTE.USER_INSURANCE}/filter`,
      params: query
    })
      .then((result) => {
        if (result.data?.data?.length === 1) {
          const userInsuranceData = result.data.data[0];
          setUserInsuranceData(userInsuranceData);
          getUser(userInsuranceData.userId);
        } else {
          navigate(-1);
        }
      })
      .catch(() => {
        navigate(-1);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const userDetailsTableOne = [
    {
      label: 'Name',
      value: userData?.name
    },
    {
      label: 'Email',
      value: userData?.email
    },
    {
      label: 'Phone',
      value: userData?.phone
    }
  ];

  const userDetailsTableTwo = [
    {
      label: 'KTP',
      value: userInsuranceData?.meta?.ktpNumber
    },
    {
      label: 'KYC Status',
      value: userData.bappebtiKycStatus
    }
  ];

  return (
    <Card>
      <CardContent>
        <Container>
          <Item>
            <Typography variant="h5">User Insurance Details</Typography>
          </Item>
        </Container>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6">User Details</Typography>

        <Container spacing={3}>
          <Item xs={6}>
            <BasicTable tableData={userDetailsTableOne} />
          </Item>
          <Item xs={6}>
            <BasicTable tableData={userDetailsTableTwo} />
          </Item>
        </Container>

        <Container sx={{ mt: 2 }}>
          <Item>
            <Typography variant="h6">User Insurance</Typography>
            <Divider sx={{ my: 2 }} />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Insurance Type</TableCell>
                  <TableCell>Status</TableCell>
                  {userInsuranceData && userInsuranceData.status === 'expired' && (
                    <TableCell>Rejection Reason</TableCell>
                  )}
                  <TableCell>Created at</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userInsuranceData ? (
                  <TableRow>
                    <TableCell>{userInsuranceData.insuranceType}</TableCell>
                    <TableCell>{userInsuranceData.status}</TableCell>
                    {userInsuranceData && userInsuranceData.status === 'expired' && (
                      <TableCell>{userInsuranceData.rejectionReason}</TableCell>
                    )}
                    <TableCell>{dateFormatter(userInsuranceData.created)}</TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell sx={{ textAlign: 'center', color: 'red' }} colSpan={5}>
                      No Data Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Item>
        </Container>
        <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
          <Button
            color="warning"
            onClick={() => navigate(-1)}
            size="small"
            variant="contained"
            startIcon={<Reply />}>
            Back
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default UserInsuranceEdit;
