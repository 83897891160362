import { useEffect, useState } from 'react';

import PluangListPage from 'components/organisms/pluangListTable';
import { REMOTE } from 'constants/serverRoutes';
import { makeRequest } from 'services';
import { useNotification } from 'services/hooks';

const OpsCryptoFutureFundingList = () => {
  const filters = [
    {
      id: 'subCategory',
      type: 'dropdown',
      label: 'Contract Name',
      default: '',
      options: [{ text: 'All', value: '' }]
    },
    {
      id: 'date',
      type: 'dateRange',
      label: 'DateFilter'
    }
  ];

  const headers = [
    { header_name: 'Trans No', db_column_name: 'id' },
    { header_name: 'Trans Type', db_column_name: 'transactionType' },
    { header_name: 'Quantity', db_column_name: 'quantity' },
    { header_name: 'Funding Fee', db_column_name: 'signedFee' },
    { header_name: 'Contract Name', db_column_name: 'subCategory' },
    { header_name: 'Created', db_column_name: 'created' },
    { header_name: 'Transaction Time', db_column_name: 'transactionTime', formatAsDate: true }
  ];

  const { showNotification } = useNotification();
  const [newFilters, setNewFilters] = useState(filters);

  useEffect(() => getAllCryptoFuturesInstruments(), []);

  const getAllCryptoFuturesInstruments = () => {
    makeRequest({
      url: `${REMOTE.CRYPTO_FUTURES}/allInstruments`
    })
      .then((result) => {
        if (result.data?.data) {
          setNewFilters((prevState) => {
            const contractNames = result.data.data.map((instrument) => {
              return {
                text: instrument.futurePairSymbol,
                value: instrument.futurePairSymbol?.toLowerCase()
              };
            });
            return prevState.map((filter) =>
              filter.id === 'subCategory'
                ? { ...filter, options: [...filter.options, ...contractNames] }
                : filter
            );
          });
        }
      })
      .catch((err) => showNotification('error', err?.message));
  };

  return (
    <PluangListPage
      filters={newFilters}
      headers={headers}
      resource="CRYPTO_FUTURE_FUNDING"
      title="Crypto Currency Future Funding"
    />
  );
};

export default OpsCryptoFutureFundingList;
