import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { toFormattedCurrency } from 'helpers/stringHelper';

const CryptoFuturesNonProducts = ({ userCryptoFuturesPortfolio }) => {
  const [open, setOpen] = useState(false);

  const marginBalance = userCryptoFuturesPortfolio?.marginWalletSummary?.marginBalance || 0;
  const marginRatioPercentage =
    userCryptoFuturesPortfolio?.marginWalletSummary?.marginRatioPercentage || 0;
  const openOrderMargin = userCryptoFuturesPortfolio?.marginWalletSummary?.openOrderMargin || 0;
  const totalMaintenanceMargin =
    userCryptoFuturesPortfolio?.marginWalletSummary?.totalMaintenanceMargin || 0;
  const walletBalanceAvailableToMove =
    userCryptoFuturesPortfolio?.marginWalletSummary?.walletBalanceAvailableToMove || 0;

  return (
    <Accordion expanded={open} onChange={() => setOpen(!open)}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h5">Non-Product</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%">
          <Table>
            <TableBody>
              <TableRow>
                <TableCell variant="head">Non Product</TableCell>
                <TableCell variant="head">Values (USDT)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Margin and USDT Value</TableCell>
                <TableCell>{toFormattedCurrency(marginBalance, {})}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Maintenance Margin</TableCell>
                <TableCell>{toFormattedCurrency(totalMaintenanceMargin, {})}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Order Margin</TableCell>
                <TableCell>{toFormattedCurrency(openOrderMargin, {})}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Available to Move</TableCell>
                <TableCell>{toFormattedCurrency(walletBalanceAvailableToMove, {})}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Margin Ratio</TableCell>
                <TableCell>{toFormattedCurrency(marginRatioPercentage, {})}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

CryptoFuturesNonProducts.propTypes = {
  userCryptoFuturesPortfolio: PropTypes.object
};

export default CryptoFuturesNonProducts;
