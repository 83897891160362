import Config from 'config';

export const filters = [
  {
    id: 'vendorTransactionId',
    type: 'text',
    label: 'Reference No.'
  },
  {
    id: 'clientId',
    type: 'dropdown',
    label: 'Client',
    default: 'all',
    option: [
      { text: 'Platform - all', value: 'all' },
      { text: 'Pluang', value: Config.PLUANG_CLIENT_ID },
      { text: 'Go Invest', value: Config.GOINVEST_CLIENT_ID },
      { text: 'Pluang Web', value: Config.PLUANG_WEB_CLIENT_ID },
      { text: 'Pluang Saham', value: Config.PLUANG_SAHAM_CLIENT_ID }
    ]
  },
  {
    id: 'status',
    type: 'dropdown',
    label: 'Status',
    default: 'QUEUED',
    option: [
      { text: 'Status - all', value: 'all' },
      { text: 'Pending', value: 'PENDING' },
      { text: 'Queued', value: 'QUEUED' },
      { text: 'Approved', value: 'APPROVED' },
      { text: 'Rejected', value: 'REJECTED' },
      { text: 'Paid', value: 'PAID' },
      { text: 'Completed', value: 'COMPLETED' },
      { text: 'Failed', value: 'FAILED' }
    ]
  },
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  }
];

export const headers = [
  { header_name: 'Name', db_column_name: 'name' },
  { header_name: 'Kyc Status', db_column_name: 'bappebtiKycStatus' },
  { header_name: 'User Bank Status', db_column_name: 'userBankStatus' },
  { header_name: 'Amount', db_column_name: 'amount', formattedCurrency: true },
  { header_name: 'Today Cashout', db_column_name: 'todayCashout', formattedCurrency: true },
  { header_name: 'Weekly Cashout', db_column_name: 'weeklyCashout', formattedCurrency: true },
  { header_name: 'Status', db_column_name: 'status' },
  { header_name: 'Edd Status', db_column_name: 'eddStatus' },
  { header_name: 'Risk Rating Category', db_column_name: 'riskRatingCategory' },
  { header_name: 'Created', db_column_name: 'created' },
  { header_name: 'Action', db_column_name: 'action', isView: 'true', isChangeStatus: 'true' }
];
