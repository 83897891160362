import { Stack, Typography } from '@mui/material';
import PluangListPage from 'components/organisms/pluangListTable';

const UsersList = () => {
  const userInfoColumn = (row) => {
    return (
      <Stack>
        <Typography>{row.name}</Typography>
        <Typography>{row.email}</Typography>
        <Typography>{row.phone}</Typography>
      </Stack>
    );
  };

  const headers = [
    { header_name: 'Partner ID', db_column_name: 'partnerId' },
    {
      header_name: 'User Info',
      db_column_name: 'userInfo',
      isCustom: true,
      customMethod: userInfoColumn
    },
    { header_name: 'Kyc Status', db_column_name: 'bappebtiKycStatus' },
    { header_name: 'Kyc2 Status', db_column_name: 'ojkKycStatus' },
    { header_name: 'Ksei Order', db_column_name: 'kseiBankOrder' },
    { header_name: 'Ksei Status', db_column_name: 'kseiStatus' },
    { header_name: 'Forex Bank', db_column_name: 'forexBank' },
    { header_name: 'Registered', db_column_name: 'created' },
    { header_name: 'Edd Status', db_column_name: 'eddStatus' },
    { header_name: 'Risk Rating Category', db_column_name: 'riskRatingCategory' },
    { header_name: 'Action', db_column_name: 'action', isView: 'true' }
  ];

  return <PluangListPage headers={headers} resource="ACCOUNT" title="Users List" />;
};

export default UsersList;
