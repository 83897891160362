import { Card, CardContent, Divider, Typography } from '@mui/material';
import BasicTable from 'components/molecules/BasicTable';
import { REMOTE } from 'constants/serverRoutes';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeRequest } from 'services';
import { useStateValue } from 'utils/redux';

// const MIN_TRANSFER_VALUE = 1000;
const MIN_TRANSFER_VALUE = 0;

const TravelRuleView = () => {
  const {
    state: { cryptoTransferData }
  } = useLocation();
  const [kyc1Data, setKyc1Data] = useState({});
  const [transferValue, setTransferValue] = useState(0);
  const [appState] = useStateValue();

  useEffect(() => {
    getKyc1Data();
    getUSDTPrice();
  }, []);

  const getKyc1Data = () => {
    const query = {};
    if (appState.sfId) {
      query.salesForceId = appState.sfId;
    }
    makeRequest({
      url: `${REMOTE.KYC1}`,
      params: query
    }).then((result) => {
      if (result.data && result.data.totalElements) {
        const kyc1Data = result.data.content[0];
        setKyc1Data(kyc1Data);
      }
    });
  };

  const getUSDTPrice = () => {
    makeRequest({
      url: `${REMOTE.CRYPTO_CURRENCY_CURRENT_PRICE}`,
      params: { symbol: 'USDT' }
    }).then((result) => {
      if (result?.data) {
        const currentUSDTPrice = result.data?.midPrice;
        const transferValueInUSD =
          (cryptoTransferData?.unitPrice * cryptoTransferData?.totalQuantity) / currentUSDTPrice;
        setTransferValue(transferValueInUSD);
      }
    });
  };

  const showData = transferValue > MIN_TRANSFER_VALUE;

  const travelRuleData = [
    { label: `Sender's Name`, value: showData ? cryptoTransferData?.info?.senderName : 'N/A' },
    {
      label: `Sender's Physical Address`,
      value: showData ? cryptoTransferData?.info?.senderAddress : 'N/A'
    },
    { label: `Sender's ID Type`, value: showData ? kyc1Data?.identityCardType : 'N/A' },
    { label: `Sender's ID Card Number`, value: showData ? kyc1Data?.identityCardNumber : 'N/A' },
    { label: `Receiver's Name`, value: showData ? cryptoTransferData?.info?.recipientName : 'N/A' },
    {
      label: `Receiver's Physical Address`,
      value: showData ? cryptoTransferData?.info?.receiverAddress : 'N/A'
    }
  ];

  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Crypto Send and Receive Travel Rule</Typography>
        <Divider sx={{ my: 2 }} />
        <BasicTable tableData={travelRuleData} />
      </CardContent>
    </Card>
  );
};

export default TravelRuleView;
