import PluangListPage from 'components/organisms/pluangListTable';

const OpsCryptoFutureTransfersList = () => {
  const filters = [
    {
      id: 'status',
      type: 'dropdown',
      label: 'Status',
      default: '',
      options: [
        { text: 'All', value: '' },
        { text: 'Success', value: 'success' },
        { text: 'Pending', value: 'pending' },
        { text: 'Failed', value: 'failed' }
      ]
    },
    {
      id: 'transactionType',
      type: 'dropdown',
      label: 'Transaction Type',
      default: '',
      options: [
        { text: 'All', value: '' },
        { text: 'Send', value: 'withdrawal' },
        { text: 'Receive', value: 'deposit' }
      ]
    },
    {
      id: 'date',
      type: 'dateRange',
      label: 'DateFilter'
    }
  ];

  const headers = [
    { header_name: 'Trans No', db_column_name: 'id' },
    { header_name: 'Trans Type', db_column_name: 'transactionType' },
    { header_name: 'Quantity', db_column_name: 'transactionAmount' },
    { header_name: 'Status', db_column_name: 'status' },
    { header_name: 'Currency Name', db_column_name: 'subCategory' },
    { header_name: 'Created', db_column_name: 'created' },
    { header_name: 'Transaction Time', db_column_name: 'transactionTime', formatAsDate: true }
  ];

  return (
    <PluangListPage
      filters={filters}
      headers={headers}
      resource="CRYPTO_FUTURE_TRANSFER"
      title="Crypto Currency Future Transfers"
    />
  );
};

export default OpsCryptoFutureTransfersList;
