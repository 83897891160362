import Config from 'config';

export const filter = [
  {
    id: 'refId',
    type: 'text',
    label: 'Reference Id'
  },
  {
    id: 'status',
    type: 'dropdown',
    label: 'Status',
    default: 'approved',
    option: [
      { text: 'Status - All', value: 'all' },
      { text: 'Waiting to be Processed', value: 'waiting_to_be_processed' },
      { text: 'Processing By S-Invest', value: 'processing_by_s_invest' },
      { text: 'Unpaid', value: 'unpaid' },
      { text: 'Pending', value: 'pending' },
      { text: 'Approved', value: 'approved' },
      { text: 'Failed', value: 'failed' },
      { text: 'Expired', value: 'expired' }
    ]
  },
  {
    id: 'clientId',
    type: 'dropdown',
    label: 'Platform',
    default: 'all',
    option: [
      { text: 'Platform - all', value: 'all' },
      { text: 'Pluang', value: Config.PLUANG_CLIENT_ID },
      { text: 'Go Invest', value: Config.GOINVEST_CLIENT_ID }
    ]
  },
  {
    id: 'transactionType',
    type: 'dropdown',
    label: 'TransactionType',
    default: 'all',
    option: [
      { text: 'Txn- All', value: 'all' },
      { text: 'Buy', value: 'buy' },
      { text: 'Sell', value: 'sell' }
    ]
  },
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  }
];

export const header = [
  { header_name: 'Id', db_column_name: 'id' },
  { header_name: 'Quantity', db_column_name: 'quantity' },
  { header_name: 'Unit Price', db_column_name: 'unitPrice' },
  { header_name: 'Fee', db_column_name: 'fee' },
  { header_name: 'Final Price', db_column_name: 'finalPrice' },
  { header_name: 'Reference Id', db_column_name: 'refId' },
  { header_name: 'Transaction Type', db_column_name: 'transactionType' },
  { header_name: 'Payment Channel', db_column_name: 'paymentChannel' },
  { header_name: 'Platform', db_column_name: 'clientId' },
  { header_name: 'Status', db_column_name: 'status' },
  { header_name: 'Created', db_column_name: 'created' },
  { header_name: 'Updated', db_column_name: 'updated' }
];
