import {
  AccountBalance,
  AutoFixHigh,
  CalendarMonthRounded,
  CalendarToday,
  CardGiftcard,
  CreditCard,
  Edit,
  EmojiEvents,
  FactCheck,
  FormatListNumbered,
  Group,
  History,
  InsertPhotoOutlined,
  LocalAtm,
  ManageAccounts,
  Money,
  TrackChanges,
  ViewInAr
} from '@mui/icons-material';

export const sidebarMenuItems = [
  {
    Icon: ManageAccounts,
    name: 'Operational',
    items: [
      {
        Icon: TrackChanges,
        name: 'Missions',
        items: [
          {
            Icon: FormatListNumbered,
            name: 'User Action',
            link: '/ops/missions/userAction'
          },
          {
            Icon: EmojiEvents,
            name: 'Reward Claim',
            link: '/ops/missions/rewardClaim'
          }
        ]
      },
      {
        Icon: TrackChanges,
        name: 'USD Deposit',
        items: [
          {
            Icon: InsertPhotoOutlined,
            name: 'Forex Topup',
            link: '/ops/usdDeposit/topup'
          },
          {
            Icon: FormatListNumbered,
            name: 'Forex Cashout',
            link: '/ops/usdDeposit/cashout'
          }
        ]
      },
      {
        Icon: AutoFixHigh,
        name: 'KYC',
        link: '/ops/kyc/agent'
      },
      {
        Icon: Group,
        name: 'Users',
        link: '/ops/users'
      },
      {
        Icon: Edit,
        name: 'Tokopedia Users',
        link: '/ops/tokopediaUsers'
      },
      {
        Icon: CreditCard,
        name: 'User Voucher',
        link: '/ops/userVoucher'
      },
      {
        Icon: ViewInAr,
        name: 'Gold Withdrawal',
        link: '/ops/withdrawal'
      },
      {
        Icon: ViewInAr,
        name: 'User Insurance',
        link: '/ops/userInsurance'
      },
      {
        Icon: CalendarMonthRounded,
        name: 'Auto Debit',
        link: '/ops/autoDebit'
      },
      {
        Icon: AccountBalance,
        name: 'User Bank Accounts',
        link: '/ops/userBankAccounts'
      },
      {
        Icon: History,
        name: 'Transactions',
        items: [
          {
            Icon: History,
            name: 'ID Stock Transactions',
            link: '/ops/indonesianSingleStockTransactions'
          },
          {
            Icon: History,
            name: 'Crypto Transactions',
            link: '/ops/cryptoTransactions'
          },
          {
            Icon: History,
            name: 'Mutual Fund Transactions',
            link: '/ops/mutualFundTransactions'
          },
          {
            Icon: History,
            name: 'GSS Transactions CFD',
            link: '/ops/globalSingleStockTransactions'
          },
          {
            Icon: History,
            name: 'GSS Transactions PALN',
            link: '/ops/globalSingleStockTransactionPaln'
          },
          {
            Icon: History,
            name: 'Gold Send Transactions',
            link: '/ops/goldSendTransactions'
          },
          {
            Icon: History,
            name: 'Gold Transactions',
            link: '/ops/goldTransactions'
          },
          {
            Icon: History,
            name: 'USD Transactions',
            link: '/ops/USDTransactions'
          },
          {
            Icon: History,
            name: 'US Index Transactions',
            link: '/ops/USIndexTransactions'
          },
          {
            Icon: History,
            name: 'Fund Transactions',
            link: '/ops/fundTransactions'
          },
          {
            Icon: History,
            name: 'Pocket Transactions',
            link: '/ops/pocketTransactions'
          },
          {
            Icon: History,
            name: 'Crypto Transfers',
            link: '/ops/cryptoTransfers'
          },
          {
            Icon: History,
            name: 'Crypto Future Transactions',
            link: '/ops/cryptoFutureTransactions'
          },
          {
            Icon: History,
            name: 'Crypto Future Funding',
            link: '/ops/cryptoFutureFunding'
          },
          {
            Icon: History,
            name: 'Crypto Future Transfers',
            link: '/ops/cryptoFutureTransfers'
          },
          {
            Icon: History,
            name: 'Options Transactions',
            link: '/ops/optionsTransactions'
          }
        ]
      }
    ]
  },
  {
    Icon: AccountBalance,
    name: 'Finance',
    items: [
      {
        Icon: CalendarToday,
        name: 'Installment',
        link: '/fi/installment'
      },
      {
        Icon: CreditCard,
        name: 'Topup',
        link: '/fi/topup'
      },
      {
        Icon: FactCheck,
        name: 'BCA',
        items: [
          {
            Icon: Money,
            name: 'BCA Topup',
            link: '/fi/bca/topup'
          },
          {
            Icon: Money,
            name: 'Bca Credit Notification',
            link: '/fi/bca/creditNotification'
          }
        ]
      },
      {
        Icon: LocalAtm,
        name: 'Rdn Cashout',
        link: '/fi/rdnCashout'
      },
      {
        Icon: CardGiftcard,
        name: 'Referral',
        link: '/fi/referral'
      },
      {
        Icon: LocalAtm,
        name: 'Cashout',
        link: '/fi/cashout'
      },
      {
        Icon: History,
        name: 'RDN Top Up',
        link: '/fi/rdnTopUpList'
      }
    ]
  }
];
