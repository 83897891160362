import { useEffect, useState } from 'react';

import PluangListPage from 'components/organisms/pluangListTable';
import { REMOTE } from 'constants/serverRoutes';
import { makeRequest } from 'services';
import { useNotification } from 'services/hooks';

const OpsCryptoFutureTransactionList = () => {
  const filters = [
    {
      id: 'subCategory',
      type: 'dropdown',
      label: 'Contract Name',
      default: '',
      options: [{ text: 'All', value: '' }]
    },
    {
      id: 'status',
      type: 'dropdown',
      label: 'Status',
      default: '',
      options: [
        { text: 'All', value: '' },
        { text: 'Success', value: 'success' },
        { text: 'Pending', value: 'pending' },
        { text: 'Partially filled', value: 'partially_filled' },
        { text: 'Failed', value: 'failed' },
        { text: 'Expired', value: 'expired' },
        { text: 'Cancelled', value: 'cancelled' },
        { text: 'Cancel requested', value: 'cancel_requested' }
      ]
    },
    {
      id: 'transactionType',
      type: 'dropdown',
      label: 'Transaction Type',
      default: '',
      options: [
        { text: 'All', value: '' },
        { text: 'Buy', value: 'buy' },
        { text: 'Sell', value: 'sell' }
      ]
    },
    {
      id: 'date',
      type: 'dateRange',
      label: 'DateFilter'
    }
  ];

  const headers = [
    { header_name: 'Trans No', db_column_name: 'id' },
    { header_name: 'Trans Type', db_column_name: 'transactionType' },
    { header_name: 'Quantity', db_column_name: 'quantity' },
    { header_name: 'Unit Price', db_column_name: 'unitPrice', formattedCurrency: true },
    { header_name: 'Leverage', db_column_name: 'leverage' },
    {
      header_name: 'Maker Fee',
      db_column_name: 'totalMakerFee',
      formattedCurrency: true
    },
    { header_name: 'Taker Fee', db_column_name: 'totalTakerFee', formattedCurrency: true },
    { header_name: 'Realized PnL', db_column_name: 'realisedGain', formattedCurrency: true },
    { header_name: 'Order Status', db_column_name: 'status' },
    { header_name: 'Filled %', db_column_name: 'filledPercent' },
    { header_name: 'Event', db_column_name: 'event' },
    { header_name: 'Order Type', db_column_name: 'orderType' },
    { header_name: 'Order Instructions', db_column_name: 'execInst' },
    { header_name: 'Contract Name', db_column_name: 'subCategory' },
    { header_name: 'Created', db_column_name: 'created' },
    { header_name: 'Transaction Time', db_column_name: 'transactionTime', formatAsDate: true }
  ];

  const { showNotification } = useNotification();
  const [newFilters, setNewFilters] = useState(filters);

  useEffect(() => getAllCryptoFuturesInstruments(), []);

  const getAllCryptoFuturesInstruments = () => {
    makeRequest({
      url: `${REMOTE.CRYPTO_FUTURES}/allInstruments`
    })
      .then((result) => {
        if (result.data?.data) {
          setNewFilters((prevState) => {
            const contractNames = result.data.data.map((instrument) => {
              return {
                text: instrument.futurePairSymbol,
                value: instrument.futurePairSymbol?.toLowerCase()
              };
            });
            return prevState.map((filter) =>
              filter.id === 'subCategory'
                ? { ...filter, options: [...filter.options, ...contractNames] }
                : filter
            );
          });
        }
      })
      .catch((err) => showNotification('error', err?.message));
  };

  return (
    <PluangListPage
      filters={newFilters}
      headers={headers}
      resource="CRYPTO_FUTURE_TRANSACTIONS"
      title="Crypto Currency Future Transactions"
    />
  );
};

export default OpsCryptoFutureTransactionList;
