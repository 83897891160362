export const filter = [
  {
    id: 'status',
    type: 'multiDropDown',
    label: 'Status Type',
    default: [],
    multiple: 'true',
    placeholder: 'Status - All',
    option: [
      { name: 'Pending', value: 'PENDING' },
      { name: 'Success', value: 'SUCCESS' },
      { name: 'Ops Checking', value: 'OPS_CHECKING' },
      { name: 'Expired', value: 'EXPIRE' },
      { name: 'Canceled', value: 'CANCELED' },
      { name: 'Deny', value: 'DENY' },
      { name: 'Failed', value: 'FAILED' },
      { name: 'Unpaid', value: 'UNPAID' }
    ]
  },
  {
    id: 'type',
    type: 'multiDropDown',
    label: 'Wallet type',
    default: [],
    multiple: 'true',
    option: [
      { name: 'Ojk', value: 'OJK' },
      { name: 'Bappebti', value: 'BAPPEBTI' }
    ]
  },
  {
    id: 'date',
    type: 'dateRange',
    label: 'DateFilter'
  }
];

export const header = [
  { header_name: 'Topup Id', db_column_name: 'id' },
  { header_name: 'Created', db_column_name: 'created' },
  { header_name: 'Vendor', db_column_name: 'vendorChannel' },
  { header_name: 'Vendor Transaction Id', db_column_name: 'vendorTransactionId' },
  { header_name: 'Payment Channel', db_column_name: 'paymentChannel' },
  { header_name: 'Amount', db_column_name: 'amount', formattedCurrency: true },
  { header_name: 'Fee', db_column_name: 'fee', formattedCurrency: true },
  { header_name: 'Wallet Type', db_column_name: 'walletType' },
  { header_name: 'Status', db_column_name: 'status' }
];
