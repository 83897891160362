import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImageMagnifier from 'components/molecules/ImageMagnifier';
import KycTask from 'components/molecules/KycTask';
import ListDialog from 'components/molecules/ListDialog';
import S3DownloadLink from 'components/molecules/s3DownloadLink';
import { dateFormatter } from 'helpers/dateChanger';
import { makeRequest } from 'services/APIService';
import { REMOTE } from 'constants/serverRoutes';
import KycTaskTable from 'components/molecules/KycTaskTable';
import { occupationTableHeaders } from './constants';
import { useStateValue } from 'utils/redux';
import { OPEN_MODAL } from 'utils/redux/actions';
import { useLoader, useNotification } from 'services/hooks';

const listHeaders = ['createdAt', 'fromState', 'toState', 'rejectionReason', 'rejectionReasonId'];

const Occupation = ({ escalate, occupationData }) => {
  const [occupationState, setOccupationState] = useState({
    rejectionReasonLogData: [],
    npwpNumber: ''
  });

  const [showDetail, setShowDetail] = useState(false);
  const { showNotification } = useNotification();
  const [, dispatch] = useStateValue();
  const [, setLoader] = useLoader();

  useEffect(() => {
    setOccupationState((prevState) => ({
      ...prevState,
      npwpNumber: occupationData.npwpNumber
    }));
  }, [occupationData.npwpNumber]);

  const handleDropdown = (action) => {
    setShowDetail(!action);
  };

  const getRejectionReasonData = (userId) => {
    setLoader(true);
    return makeRequest({
      url: `${REMOTE.OCCUPATION}/stateTransition/`,
      params: {
        userId,
        toState: 'REJECTED'
      }
    })
      .then((result) => result)
      .finally(() => {
        setLoader(false);
      });
  };

  const getRejectionReasonLog = () => {
    if (!occupationState.rejectionReasonLogData.length > 0) {
      getRejectionReasonData(occupationData.userId).then((result) => {
        if (result.data && result.data.data && result.data.data.length > 0) {
          setOccupationState((prevState) => ({
            ...prevState,
            rejectionCount: result.data.data.length,
            rejectionReasonLogData: result.data.data
          }));
          openRejectionReasonDialog(result.data.data);
        } else {
          showNotification('error', 'No rejection reason logs found');
        }
      });
    }
  };

  const openRejectionReasonDialog = (data) => {
    dispatch({
      type: OPEN_MODAL,
      modalContent: <ListDialog title="Rejection Reason logs" data={data} headers={listHeaders} />
    });
  };

  const getRejectionReasonInIndonesianLang = (rejectionReasonJson) => {
    const rejectionData = rejectionReasonJson && rejectionReasonJson.id;
    const rejectionReason = [];
    if (Array.isArray(rejectionData)) {
      rejectionData.forEach((reason) => {
        rejectionReason.push(reason.value);
      });
    } else {
      return rejectionData;
    }
    return rejectionReason.join(',');
  };

  const occupationTableValues = [
    occupationData.position,
    occupationData.companyName,
    occupationData.companyCountry,
    occupationData.companyAddress,
    occupationData.companyProvince,
    occupationData.companyCity,
    occupationData.companyDistrict,
    occupationData.companySubDistrict,
    occupationData.businessNature,
    occupationData.companyPostalCode,
    occupationData.rejectionReason
      ? getRejectionReasonInIndonesianLang(occupationData.rejectionReason)
      : '',
    !occupationData.signedNpwpPicture && !occupationData.npwpNumber
      ? `User doesn't have NPWP, please check consent letter below`
      : occupationData.npwpNumber,
    String((occupationData && occupationData.autoVerified) || ''),
    occupationData.signedConsentLetter ? (
      <S3DownloadLink url={occupationData.signedConsentLetter} />
    ) : occupationData.npwpNumber || occupationData.signedNpwpPicture ? (
      ''
    ) : (
      'Waiting for NPWP to be uploaded, proceed to approve'
    ),
    occupationData.lastUploadTime ? dateFormatter(occupationData.lastUploadTime) : '',
    occupationData.updatedAt ? dateFormatter(occupationData.updatedAt) : ''
  ];

  const kycTaskStatus = () => (
    <>
      {escalate && escalate.isOccupationEscalated && (
        <span className="escalated-status">ESCALATED</span>
      )}
      {occupationData.status === 'VERIFIED' ? (
        <span style={{ color: 'green' }}>{occupationData.status}</span>
      ) : (
        <span style={{ color: 'black' }}>{occupationData.status}</span>
      )}
    </>
  );

  const handleKycTaskClick = () => {
    handleDropdown(showDetail);
  };

  const isTaskDisabled = occupationData.status === 'NOT UPLOADED';

  return (
    <>
      {(Object.keys(occupationData).length && (
        <KycTask
          title="Occupation"
          status={kycTaskStatus()}
          onClick={handleKycTaskClick}
          disabled={isTaskDisabled}
          open={showDetail}>
          <KycTaskTable headers={occupationTableHeaders} values={occupationTableValues} />
          <div>
            <Box my={2} display="flex" justifyContent="center">
              {!(
                occupationState.rejectionReasonLogData &&
                occupationState.rejectionReasonLogData.length > 0
              ) && (
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  className="btnRight"
                  onClick={getRejectionReasonLog}>
                  Rejection reason logs
                </Button>
              )}
              {occupationState.rejectionReasonLogData &&
                occupationState.rejectionReasonLogData.length > 0 && (
                  <ListDialog
                    title={'Rejection Reason logs'}
                    data={occupationState.rejectionReasonLogData}
                    headers={listHeaders}
                    buttonTitle={'Rejection Reason logs'}
                    defaultOpen
                  />
                )}
            </Box>
          </div>
          {occupationData.id > 0 ? (
            <div className="workingArea">
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <h6 className={'foSize'}>npwp picture</h6>
                  <div className="fluid">
                    <div className="fluid__image-container">
                      {occupationData.signedNpwpPicture ? (
                        <ImageMagnifier url={occupationData.signedNpwpPicture} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="noFound">No Data Found</div>
          )}
        </KycTask>
      )) ||
        null}
    </>
  );
};

Occupation.propTypes = {
  escalate: PropTypes.object,
  occupationData: PropTypes.object
};

export default Occupation;
